@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap');



.App {
  text-align: center;
  background-color: #FFFAE3; /* Vibrant blue background */
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif;
}

.image-container {
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  height: 100vh; /* Make the container full height of the viewport */
}

.centered-image {
  max-width: 65%; 
  max-height: 30vh;
  height: auto; 
  width: auto; 
}


.albert-sans {
  font-family: "Albert Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}



h1 {
  margin-bottom: 10px;
  font-size: 2em; /* Adjust the size as needed */
  font-family: 'Roboto', sans-serif;
}

.disclaimer {
  text-align: left;
  left: 1700px;
  width: 45%;
}

.title2 {
  width: 45%;
  font-family: 'Albert Sans';
  font-weight: 500;
}

.headline {
  font-size: 24px;
  text-align: left;
  font-family: 'Albert Sans';
}

.subline{
  font-size: 20px;
  text-align: left;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 10px;
  margin-left: 5px;
}

h5 {
  margin-top: 0px;
  opacity: 1; /* fully opaque */
  color: #000000;
  font-family: 'Albert Sans', sans-serif; /* corrected spelling */
  font-weight: 400;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 25px;
}

ul {
  margin-top: -2vh;
  list-style-type: none; /* to remove default bullet styling */
  padding-left: 0; /* to align with the rest of the text if needed */
  margin-bottom: 5vh;
}

ul li::before {
  content: '- '; /* this will add the dash before each list item */
  padding-right: 5px; /* adjust space between the dash and the list item text */
}

.footerDisc {
  text-align: left;
  padding-top: 15px;
  width: 85%;
  opacity: 0.8;
  font-family: 'Albert Sans', sans-serif;
  font-size: 14px;
}

.disclaimerData {
  opacity: 0.6;
  font-style: italic;
}

.location-input {
  padding: 10px;
  border-color: 2px solid #000000;
  border-radius: 11px;
  background: #FFFFFF;
  font-size: 22px;
  width: 95%;
  top: 555px;
}

a {
  text-decoration: underline;
  color: #1a1818;
  opacity: 0.75;
}

.calculate-button {
  padding: 10px 20px;
  background-color: #000000;
  border: none;
  border-radius: 40px;
  width: 98%;
  height: 50px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calculate-button:hover {
  background-color: #3E68FE;
}

@keyframes fadeIn-SlideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.results-section {
  margin-top: 20px;
}

.result-box {
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid;
  border-radius: 8px;
  border-color: #3E68FE;
  margin: 10px 0;
  padding: 20px;
  border-radius: 10px;
  animation: fadeIn-SlideUp 1s ease-in;
}

h2 {
  font-size: 22px;
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 500;
  text-align: left;
}

.blue {
  color: #3E68FE;
  font-weight: 700;
}

.subtitle {
  position: absolute;
  bottom: 10px;
  width: 100%;
  font-size: 0.8em;
  opacity: 0.9;
}

.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.arrow {
  font-size: 24px;
}

.travel-mode-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  border: 0.5px solid;
  border-radius: 10px;
  padding: 20px;
}

.travel-mode-label {
  margin: 0 10px;
  font-size: 18px;
}

.travel-mode-input {
  display: none;
}

.travel-mode-label span {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
  background-color: #FDF5D0;
  border: 2px solid transparent;
}

.travel-mode-input:checked + .travel-mode-label span {
  background-color: #FFFFFF;
  border: 2px solid #3E68FE;
  color: #000000;
}




/* Ensure mobile responsiveness */
/*iphone 11 Pro: width 1225px, iphone 11 width 828, height 1792*/
@media (max-width: 600px) {
  .main-box {
    width: 70%; /* Adjust the width on mobile devices */
  }

  .location-input {
    font-size: 22px;
  }

  .title2, .disclaimer {
    width: 70%;
  }
}


.block {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.dashed-path {
  border: none;
  border-top: 3px dashed #000000;
  height: 1px;
  width: 80px;
  margin: 20px auto;
  opacity: 0.1;
  transition: opacity 0.5s ease-in-out;
}

.visible .dashed-path {
  opacity: 1;
}

.faded {
  opacity: 0.2;
  transition: opacity 0.5s ease-in-out;
}

.visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
